var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-sidebar',{staticClass:"sidebarx",attrs:{"parent":"body","default-index":_vm.getDefaultIndex(),"color":"primary","click-not-close":true,"spacer":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"header-sidebar",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header-desc"},[_c('vs-button',{attrs:{"icon":"close","color":"primary","type":"flat"},on:{"click":function($event){_vm.active = false}}})],1),(_vm.user.userprofile.picture !== '#')?[_c('vs-avatar',{attrs:{"size":"70px","src":_vm.user.userprofile.picture}})]:_c('vs-avatar',{attrs:{"size":"70px","src":require('@/assets/images/user-avatar.png')}}),_c('h4',{staticClass:"small-font"},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.lastName)+" "+_vm._s(_vm.user.secondLastName)+" ")]),_c('span',{staticClass:"small-font"},[_vm._v(_vm._s(_vm.user.email))]),_c('h4',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.consultingRoom.name))])],2),_c('div',{on:{"click":function($event){return _vm.selectPage('Mi Perfil')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
        'active-route': _vm.selectedPage === 'ReMi Perfilportes',
      },attrs:{"to":{
        name: 'MyProfile',
      }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Mi Perfil ")])],1),(_vm.user.userprofile.role === _vm.roles.PATIENT)?_c('vs-sidebar-group',{attrs:{"title":"Progreso","open":true}},[(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.NUTRICION
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Mis Citas')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Mis Citas',
        },attrs:{"to":{
          name: 'patientAppointments',
          params: { patientId: _vm.user.patient },
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" view_list ")]),_vm._v(" Mis Citas ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.GENERAL
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Mis Citas')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Mis Citas',
        },attrs:{"to":{
          name: 'patientAppointmentsGeneral',
          params: { patientGeneralId: _vm.user.patientgeneral },
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" view_list ")]),_vm._v(" Mis Citas ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.NUTRICION
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Mis Avances')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Mis Avances',
        },attrs:{"to":{
          name: 'patientProgress',
          params: { id: _vm.user.patient },
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" show_chart ")]),_vm._v(" Mis Avances ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.GENERAL
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Mis Avances')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Mis Avances',
        },attrs:{"to":{
          name: 'patientGeneralProgress',
          params: { id: _vm.user.patientgeneral },
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" show_chart ")]),_vm._v(" Mis Avances ")])],1):_vm._e()]):_vm._e(),(
      !(
        _vm.user.userprofile.role === _vm.roles.ADMIN ||
        _vm.user.userprofile.role === _vm.roles.DOCTOR
      )
    )?_c('vs-divider',{attrs:{"position":"left"}}):_vm._e(),(
      _vm.user.userprofile.role === _vm.roles.ASSISTANT ||
      _vm.user.userprofile.role === _vm.roles.ADMIN ||
      _vm.user.userprofile.role === _vm.roles.DOCTOR
    )?_c('vs-sidebar-group',{attrs:{"title":"Pacientes","open":true}},[(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.NUTRICION
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Lista de Pacientes')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Lista de Pacientes',
        },attrs:{"to":{
          name: 'patientsList',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Lista de Pacientes ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.GENERAL
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Lista de Pacientes Generales')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Lista de Pacientes Generales',
        },attrs:{"to":{
          name: 'patientsGeneralList',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Lista de Pacientes Generales ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.NUTRICION
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Agregar Paciente')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Agregar Paciente',
        },attrs:{"to":{
          name: 'newPatient',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" person_add ")]),_vm._v(" Agregar Paciente ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.NUTRICION
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Agregar Paciente Prospecto')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Agregar Paciente Prospecto',
        },attrs:{"to":{
          name: 'newProspectPatient',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" person_add ")]),_vm._v(" Agregar Paciente Prospecto ")])],1):_vm._e(),(
        _vm.user.userprofile.consultingRoom.consultingRoomType.id ===
        _vm.consultingRoomTypes.GENERAL
      )?_c('div',{on:{"click":function($event){return _vm.selectPage('Agregar Paciente General')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Agregar Paciente General',
        },attrs:{"to":{
          name: 'newPatientGeneral',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" person_add ")]),_vm._v(" Agregar Paciente General ")])],1):_vm._e()]):_vm._e(),(
      _vm.consultingRoom.consultingRoomType.allowPrescriptions !== undefined &&
      _vm.consultingRoom.consultingRoomType.allowPrescriptions &&
      (_vm.user.userprofile.role === _vm.roles.ASSISTANT ||
        _vm.user.userprofile.role === _vm.roles.ADMIN ||
        _vm.user.userprofile.role === _vm.roles.DOCTOR)
    )?_c('vs-sidebar-group',{attrs:{"title":"Prescripciones","open":true}},[_c('div',{on:{"click":function($event){return _vm.selectPage('Configuración de Prescripciones')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Configuración de Prescripciones',
        },attrs:{"to":{
          name: 'PrescriptionConfiguration',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" article ")]),_vm._v(" Configuración de Prescripciones ")])],1)]):_vm._e(),(
      _vm.user.userprofile.role === _vm.roles.ADMIN ||
      _vm.user.userprofile.role === _vm.roles.DOCTOR
    )?_c('vs-sidebar-group',{attrs:{"title":"Mi Consultorio","open":true}},[_c('div',{on:{"click":function($event){return _vm.selectPage('Pacientes')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Pacientes',
        },attrs:{"to":{
          name: 'ConsultingRoomPatients',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Pacientes ")])],1),_c('div',{on:{"click":function($event){return _vm.selectPage('DocumentosImpresion')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'DocumentosImpresion',
        },attrs:{"to":{
          name: 'DocumentsPrinting',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" file_present ")]),_vm._v(" Documentos de impresión rápida ")])],1),_c('div',{on:{"click":function($event){return _vm.selectPage('Usuarios')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Usuarios',
        },attrs:{"to":{
          name: 'ConsultingRoomAssistants',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" supervised_user_circle ")]),_vm._v(" Usuarios ")])],1),(_vm.consultingRoom.consultingRoomType.id != 3)?_c('div',{on:{"click":function($event){return _vm.selectPage('Reportes')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Reportes',
        },attrs:{"to":{
          name: 'consultingRoomReports',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" style ")]),_vm._v(" Reportes ")])],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.selectPage('Redes Sociales')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Redes Sociales',
        },attrs:{"to":{
          name: 'consultingRoomSocialNetworks',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" public ")]),_vm._v(" Redes Sociales ")])],1),_c('div',{on:{"click":function($event){return _vm.selectPage('Infografias')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Infografias',
        },attrs:{"to":{
          name: 'consultingRoomInfographics',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" notification_important ")]),_vm._v(" Infografias ")])],1),_c('div',{on:{"click":function($event){return _vm.selectPage('Catálogos')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Catálogos',
        },attrs:{"to":{
          name: 'catalogs',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" notification_important ")]),_vm._v(" Catálogos ")])],1),(_vm.user.userprofile.role === _vm.roles.ADMIN)?_c('div',{on:{"click":function($event){return _vm.selectPage('Nuevo Consultorio')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Nuevo Consultorio',
        },attrs:{"to":{
          name: 'consultingRoom',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" fiber_new ")]),_vm._v(" Nuevo Consultorio ")])],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.selectPage('Configuración General')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{
          'active-route': _vm.selectedPage === 'Configuración General',
        },attrs:{"to":{
          name: 'consultingRoomGeneralSettings',
        }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" settings ")]),_vm._v(" Configuración General ")])],1)]):_vm._e(),_c('div',{staticClass:"footer-sidebar",attrs:{"slot":"footer"},slot:"footer"},[_c('vs-button',{attrs:{"icon":"reply","color":"danger","type":"flat"},on:{"click":_vm._logout}},[_vm._v(" Cerrar Sesión ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }